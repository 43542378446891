<template>
  <app-modal-copy-business
    v-if="editMode"
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="structure"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.structure.title") }}
      </h1>
      <app-icon
        v-if="showCopyBusinessIcon"
        name="ClipboardCopyIcon"
        class="w-6 text-gray-400 cursor-pointer"
        @click="showModalCopyBusiness = true"
      />
    </div>
    <p class="mt-4">
      {{ $t("business.structure.description") }}
    </p>
  </div>
  <div class="w-full">
    <form @submit.prevent="businessStructureForm">
      <app-form-country
        :allowFilter="true"
        :showIcon="true"
        :labelText="$t('business.structure.country_label')"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.country"
        :errorMessage="errors.country_id"
        :disabled="!editMode"
      />

      <div class="flex flex-col lg:flex-row mb-5 mt-5">
        <app-form-input
          id="business.name"
          name="business.name"
          type="text"
          :placeholder="$t('general.name')"
          :labelText="$t('business.structure.info_label')"
          class="lg:w-7/12 w-full"
          v-model="business.name"
          :errorMessage="errors.name"
          @onblur="onBusinessBrandFocus = false"
          @onfocus="onBusinessBrandFocus = true"
          :disabled="!editMode"
        />

        <div
          v-if="onBusinessBrandFocus"
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            pt-3
            pb-3
            pl-2
            pr-2
            text-gray-500
            lg:w-1/3
            w-full
            lg:ml-10
            ml-0
            mt-2
            lg:mt-6
            text-sm
          "
        >
          {{ $t("business.structure.info_info") }}
        </div>
      </div>

      <div class="flex flex-col lg:flex-row mb-5 mt-5">
        <div class="flex flex-col lg:w-7/12 w-full">
          <app-form-input
            id="business.address_1"
            name="business.address_1"
            type="text"
            :placeholder="$t('general.address.line_1')"
            :labelText="$t('business.structure.address_label')"
            v-model="business.address_1"
            :errorMessage="errors.address_1"
            @onblur="onBusinessAddressFocus = false"
            @onfocus="onBusinessAddressFocus = true"
            :disabled="!editMode"
          />

          <app-form-input
            id="business.address_2"
            name="business.address_2"
            type="text"
            :placeholder="$t('general.address.line_2')"
            v-model="business.address_2"
            :errorMessage="errors.address_2"
            @onblur="onBusinessAddressFocus = false"
            @onfocus="onBusinessAddressFocus = true"
            :disabled="!editMode"
          />

          <app-form-input
            id="business.postcode"
            name="business.postcode"
            type="number"
            :placeholder="$t('general.address.postcode')"
            v-model="business.postcode"
            :errorMessage="errors.postcode"
            @onblur="onBusinessAddressFocus = false"
            @onfocus="onBusinessAddressFocus = true"
            :disabled="!editMode"
            :numberOnly="true"
          />

          <app-form-input
            id="business.city"
            name="business.city"
            type="text"
            :placeholder="$t('general.address.city')"
            v-model="business.city"
            :errorMessage="errors.city"
            @onblur="onBusinessAddressFocus = false"
            @onfocus="onBusinessAddressFocus = true"
            :disabled="!editMode"
          />

          <app-form-state
            :showLabel="false"
            :placeholder="$t('general.address.state')"
            :country_id="business.country?.id ?? 0"
            v-model="business.state"
            :errorMessage="errors.state_id"
            :disabled="!editMode"
          />
        </div>

        <div
          v-if="onBusinessAddressFocus"
          class="
            flex-none
            bg-gray-100
            h-full
            rounded-lg
            pt-3
            pb-3
            pl-2
            pr-2
            text-gray-500
            lg:w-1/3
            w-full
            lg:ml-10
            ml-0
            mt-2
            lg:mt-6
            text-sm
          "
        >
          {{ $t("business.structure.address_info") }}
        </div>
      </div>

      <app-form-type-business
        :labelText="$t('business.structure.type_of_business_label')"
        class="lg:w-7/12 w-full mb-5"
        :country_id="business.country?.id ?? 0"
        v-model="business.type_of_business"
        :errorMessage="errors.type_of_business_id"
        :showLabel="true"
        :allowFilter="false"
        :disabled="!editMode"
      />

      <app-alert-error
        class="lg:w-7/12 w-full mb-5"
        v-if="errors.user"
        :message="errors.user[0]"
      />

      <app-button
        v-if="business.business_status_id == BUSINESS_STATUS.AMENDED"
        type="submit"
        :loading="loading"
        class="mt-10 lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.continue") }}
      </app-button>
      <app-button
        v-if="
          business.business_status_id == null ||
          business.business_status_id == BUSINESS_STATUS.CREATED
        "
        type="submit"
        :loading="loading"
        class="mt-10 lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.continue") }}
      </app-button>

      <!--Modal Continue business/ Go to dashboard-->
      <app-modal
       v-if="all_business < 1"
        :show="show_modal_businessOrDashboard"
        @close="continueRegister()"
        :textButtonCancel="$t('business.modal.continue_register')"
      >
        <template v-slot:title>{{
          $t("business.modal.go_test_mode")
        }}</template>
        <template v-slot:body>
          <p class="text-sm text-gray-500">
            {{ $t("business.modal.go_test_mode_description") }}
          </p>
        </template>
        <template v-slot:action>
          <app-button
            @click="goDashboard()"
            :showf70Icon="false"
            type="button"
            class="sm:ml-3 sm:w-auto"
          >
            {{ $t("business.modal.go_dashboard") }}
          </app-button>
        </template>
      </app-modal>
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      show_modal_businessOrDashboard: false,
      emptyErrors: {
        country: [],
        name: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        state_id: [],
        type_of_business_id: [],
      },
      onBusinessBrandFocus: false,
      onBusinessAddressFocus: false,
    };
  },
  computed: {
    loading() {
      return this.$store.getters["businessStore/loading"];
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    all_business() {
      return this.$store.getters["businessStore/all_business"];
    },
    errors() {
      return this.$store.getters["businessStore/errors"] ?? this.emptyErrors;
    },
    showCopyBusinessIcon() {
      let statusBusinessAllow = [
        BUSINESS_STATUS.CREATED,
        BUSINESS_STATUS.AMENDED,
      ];

      return (
        (this.all_business?.length > 0 &&
          statusBusinessAllow.includes(this.business.business_status_id)) ||
        this.business.business_status_id == null
      );
    },
    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED ||
        this.business.business_status_id == null
      );
    },

  },
  methods: {
    copyBusiness(business) {
      let structure = this.business;

      structure.country = business.country;
      structure.name = business.name;
      structure.address_1 = business.address_1;
      structure.address_2 = business.address_2;
      structure.postcode = business.postcode;
      structure.city = business.city;
      structure.state = business.state;
      structure.type_of_business = business.type_of_business;

      structure.country.icon = business.country?.flag;
      structure.type_of_business.name = business.type_of_business.description;

      this.business = structure;
    },

    async businessStructureForm() {
      this.resetState();

      const businessResult = await this.$store.dispatch(
        "businessStore/createOrUpdate",
        this.business
      );

      if (businessResult == null) return;

      const refreshUserResult = await this.$store.dispatch(
        "authStore/refreshUser"
      );

      if (refreshUserResult == null) {
        return;
      }

      const selectedRoleResult = await this.$store.dispatch(
        "authStore/setSelectedRoleByBusinessId",
        businessResult.id
      );

      if (selectedRoleResult == null) {
        return;
      }

      await this.$store.dispatch("businessStore/getBusinessSupportDocuments");
      await this.$store.dispatch("businessStore/validateBusinessSupportDocuments");
      await this.$store.dispatch("businessStore/typeBusinessSupportDocuments");

      if (this.all_business < 1 ) {
       this.show_modal_businessOrDashboard = true;
      } else {
         this.show_modal_businessOrDashboard = false;
        this.$router.push({ path: "/business/detail" });
        
      }
    },

    resetState() {
      this.$store.commit("businessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },

    continueRegister() {
      this.show_modal_businessOrDashboard = false;
      this.$router.push({ path: "/business/detail" });
    },

    goDashboard() {
      this.show_modal_businessOrDashboard = false;
      this.$router.push({ path: "/dashboard" });
    },
  },
};
</script>
